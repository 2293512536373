<template>
  <div>
    <section v-for="(section, idx) in sections" :key="idx">
      <SectionTitle :title="section.title" />
      <component :is="section.component" :client="client" :lineOfBusiness="lineOfBusiness" :moods="moods"
        :sellers="sellersOrdered" :agencies="agencies" />
    </section>
  </div>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import("@/components/SectionTitle"),
    FormCard: () => import("@/components/FormCard"),
    BasicData: () => import("./BasicData"),
    Contacts: () => import("./Contacts"),
    Locations: () => import("./Locations"),
    LoginData: () => import("./LoginData"),
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    sections: [
      { title: "Dados Básicos", component: "basic-data" },
      { title: "Contatos", component: "contacts" },
      { title: "Endereços", component: "locations" },
      { title: "Dados de login", component: "login-data" },
    ],
    lineOfBusiness: [],
    moods: [],
    sellers: [],
    agencies: [],
    is_ready: 0
  }),
  computed: {
    sellersOrdered() {
      return this.sellers.map(seller => {
        return {
          ...seller,
          title: seller.name + ' - ' + seller.email
        }
      }).sort((a, b) => {
        const nameA = a.name.toUpperCase(); 
        const nameB = b.name.toUpperCase(); 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    }
  },
  methods: {
    getAll() {
      this.$api
        .get("people/get_form/client")
        .then((res) => {
          this.moods = res.data.moods;
          this.sellers = res.data.sellers;
          this.agencies = res.data.agencies;
          this.lineOfBusiness = res.data.lines;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_ready = 1

        });
    },
  },
  created() {
    //Atribuindo valor do id do vendedor logado, ao cliente
    if (this.$cookies.get('type') == 'seller') {
      this.client.seller_id = parseInt(this.$cookies.get('id'))
    }
    this.getAll()
  },
};
</script>